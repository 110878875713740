import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"

import { media } from "../styles/media"

import HeaderVisual from "../components/HeaderVisual"
import Layout from "../components/Layout"
import ProductLinks from "../components/ProductLinks"
import SEO from "../components/SEO"
import { ProductList } from "../features/product-list"

const ProductsWrapper = styled.div`
  width: 100%;
  .productWrap0 {
    margin: 50px auto;
    max-width: 1200px;
    /* .productWrap {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;
    } */
  }
  ${() =>
    media.tablet(css`
      .productWrap0 {
        margin: 10px 5% 50px;
      }
    `)}
`

const ThisMonthProduct = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiProducts: { nodes: products },
  } = data
  // const conditionalLogo = ({ brand }) => {
  //   if (brand.slug === "naturel-bread") {
  //     return brand.subLogo1.childImageSharp.fluid || null
  //   }
  //   return brand.defaultLogo.childImageSharp.fluid || null
  // }
  return (
    <Layout>
      <SEO>
        <title>新商品一覧 | フランソア</title>
        <meta
          name="description"
          content="おいしい。そしてヘルシー。フランソアのパン商品ラインナップ。"
        />
      </SEO>
      <ProductsWrapper>
        <HeaderVisual
          backImage={data.file.childImageSharp.fluid}
          mainTitle="今月の新商品"
        />
        <div className="productWrap0">
          <ProductList products={products} />
        </div>
      </ProductsWrapper>

      <ProductLinks />
    </Layout>
  )
}

const query = graphql`
  {
    allStrapiProducts(
      filter: {
        tags: { elemMatch: { tag: { eq: "新商品" } } }
        isDisplay: { eq: true }
      }
      sort: { order: [ASC, ASC], fields: [brand___order, order] }
    ) {
      nodes {
        name
        slug
        excerpt
        catchPhrase
        id
        mainImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        brand {
          slug
          defaultLogo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          subLogo1 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "varieties.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ThisMonthProduct
